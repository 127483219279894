.toBeThe {
  margin: 0;
}
.subtitle,
.title {
  margin: 0;
  align-self: stretch;
  position: relative;
  line-height: 120%;
}
.title {
  font-size: inherit;
  letter-spacing: -0.03em;
  font-weight: 700;
}
.subtitle {
  font-size: 32px;
  font-weight: 400;
}
.textContentTitle {
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
}
.text,
.textContentTitle,
.textContentTitleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.textContentTitleWrapper {
  align-self: stretch;
  align-items: flex-start;
}
.text {
  width: 561px;
  align-items: flex-end;
  padding: 0 0 64px;
  box-sizing: border-box;
  min-width: 561px;
  min-height: 358px;
  max-width: 100%;
}
.graphicsIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.graphics,
.tile {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.graphics {
  height: 600px;
  width: 723px;
  flex-direction: column;
  min-width: 723px;
}
.tile {
  align-self: stretch;
  flex-direction: row;
  row-gap: 20px;
  text-align: left;
  font-size: 72px;
  color: #fff;
}
@media screen and (max-width: 1275px) {
  .text {
    flex: 1;
  }
  .graphicsIcon {
    align-self: stretch;
    width: auto;
  }
  .graphics {
    flex: 1;
  }
  .tile {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1100px) {
  .graphics {
    min-width: 100%;
  }
}
@media screen and (max-width: 750px) {
  .title {
    font-size: 58px;
    line-height: 69px;
  }
  .subtitle {
    font-size: 26px;
    line-height: 31px;
  }
  .text {
    min-width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .graphics
  {
    margin-bottom: -30px;
    margin-top: -100px;
  }
}
@media screen and (max-width: 450px) {
  .title {
    font-size: 43px;
    line-height: 52px;
  }
  .subtitle {
    font-size: 19px;
    line-height: 23px;
  }
  .text {
    padding-bottom: 42px;
    box-sizing: border-box;
  }
  .graphics
  {
    margin-bottom: -180px;
    margin-top: -250px;
  }
}
@media screen and (max-width: 300px) {
  .graphics
  {
    margin-bottom: -250px;
    margin-top: -250px;
  }
}
