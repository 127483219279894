.textTitlePage1 {
  margin: 0;
  width: 543px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 58px;
  font-weight: 700;
  display: inline-block;
}
.textTitlePage {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.digitalIdEnrolment {
  margin: 0;
}
.text1 {
  flex: 1;
  position: relative;
  line-height: 140%;
}
.text {
  align-self: stretch;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.graphicIcon,
.tiles {
  flex-shrink: 0;
  max-height: 100%;
  max-width: 100%;
}
.tiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 36px 78px;
  box-sizing: border-box;
  gap: 32px;
}
.graphicIcon {
  position: relative;
  max-height: 100%;
  overflow: hidden;
  z-index: 1;
}
.tiles1 {
  width: 357.3px;
  flex-direction: column;
  align-items: center;
  padding: 62px 78px;
  gap: 32px;
  flex-shrink: 0;
}
.doubleTilesContent,
.section,
.tiles1 {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
}
.doubleTilesContent {
  display: grid;
  flex-direction: row;
  align-items: center;
  grid-template-columns: repeat(3, minmax(270px, 400px));
  font-size: 14px;
  padding-bottom: 20px;
}
.section {
  align-self: stretch;
  background-color: #e0f4f7;
  flex-direction: column;
  align-items: center;
  padding: 64px 64px 0;
  gap: 32px;
  text-align: center;
  font-size: 48px;
  color: #2b4a6b;
}
@media screen and (max-width: 1050px) {
  .section {
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .doubleTilesContent {
    grid-row-gap: 20px;
    justify-content: center;
    grid-template-columns: minmax(270px, 460px);
  }
  .textTitlePage1 {
    font-size: 38px;
    line-height: 46px;
  }
  .section {
    gap: 16px;
    padding-top: 42px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .textTitlePage1 {
    font-size: 29px;
    line-height: 35px;
  }
  .tiles,
  .tiles1 {
    gap: 16px;
    padding: 23px 20px;
    box-sizing: border-box;
  }
  .tiles1 {
    padding: 40px 20px;
  }
}
