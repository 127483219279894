.title {
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 120%;
  font-weight: 700;
}
.atSakuWe,
.title {
  margin: 0;
}
.subtitle {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 140%;
}
.textContentTitle {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  box-sizing: border-box;
  gap: 8px;
  min-width: 600px;
  max-width: 100%;
}
.heroActionsChild {
  position: relative;
  max-height: 100%;
  max-width: 100%;
  width: 600px;
}
.heroActions,
.sakuaboutus {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heroActions {
  align-self: stretch;
  flex-direction: row;
  padding: 0 20px;
  box-sizing: border-box;
  row-gap: 20px;
  text-align: left;
  font-size: 72px;
  color: #2b4a6b;
}
.sakuaboutus {
  width: 2000px;
  background-color: #fff;
  flex-direction: column;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 1300px) {
  .heroActions {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .title {
    font-size: 58px;
    line-height: 69px;
  }
  .textContentTitle {
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .title {
    font-size: 43px;
    line-height: 52px;
  }
}
