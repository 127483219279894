.textTitlePage1 {
  margin: 0;
  width: 890px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 120%;
  font-weight: 700;
  display: inline-block;
}
.textTitlePage {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.textTitlePage3 {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 400;
}
.textTitlePage2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: 20px;
}
.graphicIcon {
  width: 1072px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  object-fit: cover;
  max-width: 100%;
}
.section {
  align-self: stretch;
  background-color: #e0f4f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 64px 0;
  box-sizing: border-box;
  gap: 64px;
  max-width: 100%;
  text-align: center;
  font-size: 40px;
  color: #2b4a6b;
}
@media screen and (max-width: 1100px) {
  .section {
    gap: 32px;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .textTitlePage1 {
    font-size: 32px;
    line-height: 38px;
  }
  .section {
    gap: 16px;
    padding-top: 42px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .textTitlePage1 {
    font-size: 24px;
    line-height: 29px;
  }
  .textTitlePage3 {
    font-size: 16px;
    line-height: 19px;
  }
}
