@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype"),
    url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype"),
    url("./assets/fonts/Roboto/Roboto-Italic.ttf") format("truetype"),
    url("./assets/fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* src/styles/colors.css */
:root {
  /* Turquoise */
  --primary-color-1: #0dbfc4;
  /* Jade */
  --primary-color-2: #00c2ab;
  /* Lime */
  --secondary-color-1: #6bdbb2;
  /* Lush */
  --secondary-color-2: #41ad49;
  /* Babylon Blue */
  --secondary-color-3: #2b4a6b;
  /* White */
  --tertiary-color-1: #ffffff;
  /* Black */
  --tertiary-color-2: #000000;
  /* Violet */
  --tertiary-color-3: #5e69b0;
  /* Pastel */
  --tertiary-color-4: #e0f4f7;
  /* Red */
  --error-message-color: red;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  line-height: normal;
}

.text-align-justify {
  text-align: justify;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
