.pocketIcon,
.sakuIcon {
  position: relative;
  flex-shrink: 0;
}
.pocketIcon {
  height: 33px;
  width: 23.6px;
}
.sakuIcon {
  align-self: stretch;
  height: 45px;
  max-width: 100%;
  overflow: hidden;
}
.sakuWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 1.1px;
}
.divider {
  margin: 0 10px; /* Adds space around the divider */
  font-size: 30px;
  color: #333; /* Adjust the color of the divider */
}

.figma,
.sakuLogo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.sakuLogo {
  flex: 1;
  overflow: hidden;
  align-items: flex-end;
  padding: 0 0 0.4px;
  gap: 4.7px;
}
.figma {
  width: 250px;
  align-items: flex-start;
}
.sakuIsALoyalty {
  margin: 0;
}
.copyrightDetails {
  width: 584px;
  position: relative;
  line-height: 140%;
  display: inline-block;
  flex-shrink: 0;
  max-width: 103%;
}
.text1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}
.copyrightLinkItems {
  position: relative;
  line-height: 140%;
}
.text2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: #2b4a6b;
}
.textLink1 {
  position: relative;
  line-height: 140%;
  display: inline-block;
  min-width: 80px;
}
.textLink {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.textLink3 {
  position: relative;
  line-height: 17px;
  display: inline-block;
  min-width: 111px;
}
.copyright,
.text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.copyright {
  align-self: stretch;
  flex-direction: row;
  gap: 15px;
  color: #0dbfc4;
}
.text {
  flex: 1;
  flex-direction: column;
  padding: 0 0 32px;
  box-sizing: border-box;
  gap: 24px;
  min-width: 372px;
  max-width: 100%;
}
.appStoreImage,
.playStoreImage {
  height: 36px;
  width: 120px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 36px;
}
.facebookIcon,
.whatsappIcon {
  height: 36px;
  width: 36px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 36px;
}
.whatsappIcon {
  display: none;
}
.socialMedia {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow icons to wrap on smaller screens */
  gap: 10px;
}
.sakuPocket1 {
  width: 240px;
  height: 200px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.footerInfo,
.title {
  display: flex;
  max-width: 100%;
}
.footerInfo {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px;
  min-width: 372px;
}
.title {
  width: 1168px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  min-width: 240px;
  font-size: 12px;
  color: #2b4a6b;
}
.textStrong1 {
  position: relative;
  line-height: 140%;
  font-weight: 600;
}
.textStrong,
.title1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.title1 {
  flex-direction: column;
  padding: 0 0 16px;
}
.listItem {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 140%;
}
.textLinkListItem {
  width: 89px;
  height: 22px;
  position: relative;
  flex-shrink: 0;
}
.listItem2 {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.listItem2,
.listItem3 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 23px;
}
.textLinkList {
  height: 140px;
  width: 262px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}
.listItem8 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 140%;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.footer {
  align-self: stretch;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 32px 20px 0 52px;
  box-sizing: border-box;
  gap: 16px;
  max-width: 100%;
  text-align: left;
  font-size: 16px;
  color: #1e1e1e;
}
@media screen and (max-width: 1225px) {
  .footer {
    padding-left: 26px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .copyright {
    flex-wrap: wrap;
  }
  .footerInfo,
  .text {
    min-width: 100%;
  }
  .footerInfo {
    text-align: center;
    align-items: center;
  }
}
