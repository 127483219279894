.subtitle,
.title {
  position: relative;
  line-height: 120%;
}
.title {
  margin: 0;
  flex: 1;
  font-size: inherit;
  letter-spacing: -0.03em;
  font-weight: 700;
  display: inline-block;
  max-width: 100%;
}
.subtitle {
  width: 151px;
  font-size: 32px;
  color: #757575;
  display: none;
  align-items: center;
}
.textContentTitle {
  width: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 90px 0;
  box-sizing: border-box;
  gap: 8px;
  min-width: 600px;
  max-width: 100%;
}
.sakuGirlIcon {
  width: 530px;
  position: relative;
  max-height: 100%;
  max-width: 100%;
}
.heroActions,
.sakuhome {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}
.heroActions {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
  row-gap: 20px;
  text-align: left;
  font-size: 72px;
  color: #2b4a6b;
}
.sakuhome {
  width: 2000px;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 1225px) {
  .heroActions {
    flex-wrap: wrap;
  }
  .textContentTitle {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 750px) {
  .title {
    font-size: 58px;
    line-height: 69px;
  }
  .subtitle {
    font-size: 26px;
    line-height: 31px;
  }
  .textContentTitle {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .title {
    font-size: 43px;
    line-height: 52px;
  }
  .subtitle {
    font-size: 19px;
    line-height: 23px;
  }
}
