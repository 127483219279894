.graphicsIcon {
  height: 400px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  min-width: 260px;
}
.textTitlePage1 {
  margin: 0;
  width: 395px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 58px;
  font-weight: 700;
  display: inline-block;
  max-width: 110%;
  flex-shrink: 0;
}
.textTitlePage {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}
.starIcon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 16px;
}
.button1 {
  align-self: stretch;
  position: relative;
  line-height: 100%;
  white-space: nowrap;
}
.arrowRightIcon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 16px;
}
.button {
  align-self: stretch;
  border-radius: 8px;
  background-color: #00b1a0;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  gap: 8px;
}
.button3 {
  position: relative;
  line-height: 100%;
  display: inline-block;
  min-width: 66px;
}
.button2,
.buttonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button2 {
  border-radius: 8px;
  background-color: #00b1a0;
  overflow: hidden;
  padding: 12px;
  gap: 7px;
  text-decoration: none; /* Removes underline */
  color: inherit; /* Inherits the text color from the button styles */
}
.buttonGroup {
  gap: 16px;
  text-align: left;
  font-size: 16px;
}
.sakucontactUs,
.section,
.text {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.text {
  width: 360px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 0;
  box-sizing: border-box;
  gap: 32px;
  min-width: 360px;
}
.sakucontactUs,
.section {
  flex-direction: row;
  justify-content: center;
}
.section {
  width: 1072px;
  padding: 0 124px;
  box-sizing: border-box;
  gap: 64px;
}
.sakucontactUs {
  align-self: stretch;
  background-color: #5e69b0;
  text-align: center;
  font-size: 48px;
  color: #fff;
}
@media screen and (max-width: 1050px) {
  .text {
    flex: 1;
  }
  .section {
    flex-wrap: wrap;
    gap: 32px;
    padding-left: 62px;
    padding-right: 62px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .textTitlePage1 {
    font-size: 38px;
    line-height: 46px;
  }
  .text {
    min-width: 100%;
  }
  .section {
    gap: 16px;
    padding-left: 31px;
    padding-right: 31px;
    box-sizing: border-box;
  }
  .sakucontactUs {
    gap: 16px;
  }
}
@media screen and (max-width: 450px) {
  .textTitlePage1 {
    font-size: 29px;
    line-height: 35px;
  }
  .text {
    gap: 16px;
  }
}
