.giftBox1Icon {
  width: 128px;
  height: 128px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.textTitlePage1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 58px;
  font-weight: 700;
}
.textTitlePage {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.textTitlePage3 {
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 120%;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
}
.textTitlePage2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  font-size: 24px;
}
.graphicIcon {
  width: 600px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  max-width: 100%;
}
.left,
.section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-width: 100%;
}
.section1 {
  align-self: stretch;
  background-color: rgba(13, 191, 196, 0.2);
  padding: 64px 64px 0;
  box-sizing: border-box;
  gap: 64px;
}
.left {
  flex: 1;
  background-color: #0dbfc4;
  min-width: 650px;
}
.graphicIcon1 {
  width: 600px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  object-fit: cover;
  max-width: 100%;
}
.section2 {
  align-self: stretch;
  background-color: rgba(0, 177, 160, 0.2);
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 64px 64px 0;
  box-sizing: border-box;
  gap: 64px;
}
.left1,
.section,
.section2 {
  display: flex;
  max-width: 100%;
}
.left1 {
  flex: 1;
  background-color: #00b1a0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-width: 650px;
}
.section {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  text-align: left;
  font-size: 48px;
  color: #fff;
}
@media screen and (max-width: 1125px) {
  .section1 {
    gap: 32px;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
  }
  .left {
    width: calc(100% - 40px);
  }
  .section2 {
    gap: 32px;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
  }
  .left1 {
    width: calc(100% - 40px);
  }
}
@media screen and (max-width: 800px) {
  .textTitlePage1 {
    font-size: 38px;
    line-height: 46px;
  }
  .section1 {
    gap: 16px;
    padding-top: 42px;
    box-sizing: border-box;
  }
  .left {
    min-width: 100%;
  }
  .section2 {
    gap: 16px;
    padding-top: 42px;
    box-sizing: border-box;
  }
  .left1 {
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .textTitlePage1 {
    font-size: 29px;
    line-height: 35px;
  }
  .textTitlePage3 {
    font-size: 19px;
    line-height: 23px;
  }
}
