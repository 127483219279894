.section {
  align-self: stretch;
  background-color: #0dbfc4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 50px 32px;
  box-sizing: border-box;
  max-width: 100%;
}
.textTitlePage1 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 120%;
  font-weight: 600;
}
.textTitlePage {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.sakuLogoIcon {
  width: 330.9px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  max-width: 100%;
}
.textTitlePage3 {
  height: 58px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 120%;
  display: inline-block;
}
.textTitlePage2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: 48px;
}
.textTitlePageParent {
  width: 425px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.whichBasicallyMeans {
  margin: 0;
}
.whichBasicallyMeansContainer {
  align-self: stretch;
  height: 44px;
  position: relative;
  font-size: 16px;
  line-height: 140%;
  display: inline-block;
}
.text {
  width: 553px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 64px;
  box-sizing: border-box;
  gap: 32px;
}
.graphics,
.graphicsIcon,
.text {
  max-width: 100%;
  flex-shrink: 0;
}
.graphicsIcon {
  align-self: stretch;
  height: 700px;
  position: relative;
  overflow: hidden;
}
.graphics {
  height: 600px;
  width: 780px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 0 0 4px;
  box-sizing: border-box;
}
.section1,
.tile {
  align-items: center;
  max-width: 100%;
}
.tile {
  width: 1136px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: -197px;
}
.section1 {
  align-self: stretch;
  background-color: #0dbfc4;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 32px;
  box-sizing: border-box;
}
.coinIcon,
.coinIcon6 {
  width: 100px;
  height: 100px;
  position: relative;
  mix-blend-mode: normal;
}
.coinParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  min-width: 100px;
}
.sakuIcon1 {
  height: 232px;
  width: 232px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 232px;
}
.graphicF,
.tile1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  max-width: 100%;
}
.tile1 {
  flex: 0.9841;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 8px 0 0;
  box-sizing: border-box;
  min-width: 328px;
  min-height: 597px;
}
.textTitlePage5 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 58px;
  font-weight: 700;
}
.textTitlePage4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px;
}
.noun {
  margin: 0;
  font-size: 14px;
  line-height: 140%;
}
.pocket {
  margin: 0;
  line-height: 120%;
}
.paragraph {
  flex: 1;
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.text2 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 32px 32px 0;
  box-sizing: border-box;
  font-size: 32px;
}
.section2,
.text1,
.text2,
.tiles {
  display: flex;
  max-width: 100%;
}
.text1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 78.8px 0 78.9px;
  box-sizing: border-box;
  min-width: 328px;
}
.section2,
.tiles {
  align-items: center;
}
.tiles {
  width: 1072px;
  flex-direction: row;
  justify-content: flex-start;
  gap: 64px;
  flex-shrink: 0;
}
.section2 {
  align-self: stretch;
  background-color: #5e69b0;
  flex-direction: column;
  justify-content: center;
  padding: 1.7px 20px 1.6px;
  box-sizing: border-box;
  text-align: left;
  font-size: 48px;
  color: #fff;
}
.textTitlePage7 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 120%;
  font-weight: 700;
}
.everythingEssentialFor {
  margin: 0;
  line-height: 140%;
}
.allYouNeed {
  line-height: 140%;
}
.forBrandsWithFewerResource {
  margin-bottom: 0;
}
.allYouNeedToGetStarted {
  margin: 0;
  padding-left: 21px;
}
.rm170 {
  font-size: 48px;
  letter-spacing: -0.02em;
  line-height: 120%;
}
.everythingEssentialForContainer {
  height: 300px;
  flex: 1;
  position: relative;
  display: inline-block;
  min-width: 289px;
  max-width: 100%;
}
.loyaltyPointsAndRewardsGi {
  margin: 0;
  font-size: inherit;
  padding-left: 21px;
}
.featuresLoyaltyPointsContainer {
  height: 199px;
  position: relative;
  line-height: 140%;
  display: inline-block;
  min-width: 224px;
}
.description {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  max-width: 100%;
  font-size: 16px;
}
.starIcon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 16px;
}
.button1 {
  position: relative;
  font-size: 16px;
  line-height: 100%;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 75px;
}
.arrowRightIcon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 16px;
}
.button {
  margin-top: 10px;
  cursor: pointer;
  border: 0;
  padding: 12px;
  background-color: #2b4a6b;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7px;
  text-decoration: none; /* Removes underline */
}
.graphicIcon,
.text3 {
  flex: 1;
  max-width: 100%;
  min-width: 455px;
}
.text3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
}
.graphicIcon {
  position: relative;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
  margin-left: -130px;
}
.sakucontactUs,
.sakusolution,
.section3 {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section3 {
  width: 1270px;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
}
.sakucontactUs,
.sakusolution {
  text-align: left;
  color: #fff;
}
.sakucontactUs {
  align-self: stretch;
  background-color: #00b1a0;
  flex-direction: row;
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 48px;
}
.sakusolution {
  width: 2000px;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;
  line-height: normal;
  letter-spacing: normal;
  font-size: 24px;
}
@media screen and (max-width: 1100px) {
  .text1,
  .tile1 {
    flex: 1;
  }
  .tiles {
    flex-wrap: wrap;
    gap: 32px;
  }
  .graphicIcon {
    margin-left: 0;
  }
  .text3 {
    padding-top: 45px;
  }
}
@media screen and (max-width: 750px) {
  .section {
    gap: 16px;
  }
  .textTitlePage3 {
    font-size: 38px;
    line-height: 46px;
  }
  .tile1 {
    gap: 16px;
  }
  .textTitlePage5 {
    font-size: 38px;
    line-height: 46px;
  }
  .text1 {
    padding-top: 51px;
    padding-bottom: 51px;
    box-sizing: border-box;
  }
  .section2,
  .tiles {
    gap: 16px;
  }
  .textTitlePage7 {
    font-size: 38px;
    line-height: 46px;
  }
  .featuresLoyaltyPointsContainer {
    flex: 1;
  }
  .description {
    flex-wrap: wrap;
    gap: 16px;
  }
  .text3 {
    padding-top: 45px;
    box-sizing: border-box;
  }
  .graphicIcon,
  .text3 {
    min-width: 100%;
  }
  .sakucontactUs {
    gap: 16px;
  }
}
@media screen and (max-width: 450px) {
  .textTitlePage1 {
    font-size: 19px;
    line-height: 23px;
  }
  .textTitlePage3 {
    font-size: 29px;
    line-height: 35px;
  }
  .coinParent,
  .sakuIcon1 {
    flex: 1;
  }
  .graphicF {
    flex-wrap: wrap;
    gap: 16px;
  }
  .textTitlePage5,
  .textTitlePage7 {
    font-size: 29px;
    line-height: 35px;
  }
}
