.textTitlePage1 {
  margin: 0;
  width: 803px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 58px;
  font-weight: 700;
  display: inline-block;
}
.textTitlePage {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.text1 {
  flex: 1;
  position: relative;
  line-height: 140%;
}
.text,
.tile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.text {
  width: 220px;
  border-radius: 9999px;
}
.tile {
  align-self: stretch;
  flex-wrap: wrap;
  padding: 0 16px;
  box-sizing: border-box;
  gap: 32px 28px;
  min-height: 112px;
}
.leftGraphicValue {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 29px;
  font-weight: 600;
}
.circle,
.text8 {
  width: 200px;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.circle {
  width: 400px;
  flex: 0.7;
  background-color: #e0f4f7;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 64px 20px;
  box-sizing: border-box;
  max-width: 100%;
}
.graphicIcon {
  position: relative;
  max-height: 100%;
  max-width: 100%;
}
.graphicIcon {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  z-index: 1;
  margin-top: -300px;
}
.tile1 {
  width: 490px;
  height: 618px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 20px 18px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: 24px;
}
.circle1,
.left,
.right,
.text9,
.tile1 {
  display: flex;
  align-items: center;
}
.left {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
  min-width: 328px;
  max-width: 100%;
}
.circle1,
.text9 {
  border-radius: 9999px;
}
.text9 {
  width: 220px;
  flex-direction: row;
  justify-content: center;
}
.circle1 {
  align-self: stretch;
  flex: 0.7;
  background-color: rgba(107, 219, 178, 0.4);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: 64px 20px;
}
.graphicIcon1{
  position: relative;
  max-height: 100%;
  max-width: 100%;
}
.graphicIcon1 {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  z-index: 0.5;
  margin-top: -340px;
}
.tile3 {
  width: 490px;
  height: 618px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: center;
  font-size: 24px;
}
.right,
.section,
.tile3,
.tiles {
  display: flex;
  max-width: 100%;
}
.right {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
  min-width: 328px;
  max-width: 100%;
  color: #00b1a0;
}
.section,
.tiles {
  justify-content: center;
}
.tiles {
  width: 1072px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 64px;
  text-align: left;
  font-size: 14px;
}
.section {
  align-self: stretch;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  padding: 64px 32px 0;
  box-sizing: border-box;
  gap: 32px;
  text-align: center;
  font-size: 48px;
  color: #2b4a6b;
}
@media screen and (max-width: 1050px) {
  .tiles {
    gap: 32px;
  }
  .section {
    padding-top: 42px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .textTitlePage1 {
    font-size: 38px;
    line-height: 46px;
  }
  .left,
  .right,
  .section,
  .tiles {
    gap: 16px;
  }
}
@media screen and (max-width: 450px) {
  .textTitlePage1 {
    font-size: 29px;
    line-height: 35px;
  }
  .leftGraphicValue {
    font-size: 19px;
    line-height: 23px;
  }
  .section {
    padding-top: 27px;
    box-sizing: border-box;
  }
  .circle, .circle1 {
    flex: 0.2;
  }
  .tile1{
    margin-bottom: -180px;
  }
}
