.textTitlePage1 {
  margin: 0;
  width: 585px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 58px;
  font-weight: 700;
  display: inline-block;
}
.textTitlePage {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.x {
  margin: 0;
}
.costs5Times {
  margin: 0;
  font-size: 14px;
  line-height: 140%;
}
.text2 {
  position: relative;
}
.text1 {
  flex: 1;
  border-right: 1px solid #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 32px 31px;
  min-width: 198px;
  min-height: 234px;
}
.b {
  letter-spacing: -0.02em;
  line-height: 120%;
}
.text,
.text3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.text3 {
  flex: 1;
  padding: 32px 32px 25px;
  box-sizing: border-box;
  min-width: 198px;
}
.text {
  align-self: stretch;
  overflow: hidden;
  row-gap: 20px;
}
.text6 {
  flex: 1;
  position: relative;
  line-height: 140%;
  display: inline-block;
  max-width: 100%;
}
.text5,
.tile {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}
.text5 {
  align-self: stretch;
  border-top: 1px solid #fff;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 32px 32px;
  font-size: 14px;
}
.tile {
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
  min-width: 395px;
}
.graphicIcon,
.graphicIcon1 {
  width: 400px;
  position: relative;
  max-width: 100%;
}
.graphicIcon {
  height: 500px;
  display: none;
}
.graphicIcon1 {
  max-height: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.section,
.tiles {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.tiles {
  width: 1072px;
  flex-direction: row;
  gap: 64px;
}
.section {
  align-self: stretch;
  background-color: #2b4a6b;
  flex-direction: column;
  padding: 64px 64px 0;
  box-sizing: border-box;
  gap: 32px;
  text-align: center;
  font-size: 48px;
  color: #fff;
}
@media screen and (max-width: 1050px) {
  .graphicIcon1 {
    flex: 1;
  }
  .tiles {
    flex-wrap: wrap;
    gap: 32px;
  }
  .section {
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .textTitlePage1 {
    font-size: 38px;
    line-height: 46px;
  }
  .text1 {
    min-height: auto;
  }
  .text {
    flex-wrap: wrap;
  }
  .tile {
    min-width: 100%;
  }
  .tiles {
    gap: 16px;
  }
  .section {
    gap: 16px;
    padding-top: 42px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .textTitlePage1 {
    font-size: 29px;
    line-height: 35px;
  }
}
