.textTitlePage1 {
  margin: 0;
  width: 278px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 58px;
  font-weight: 700;
  display: inline-block;
}
.textTitlePage {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.textTitleHero1 {
  width: 36px;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 120%;
  display: inline-block;
}
.textTitleHero {
  align-self: stretch;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.text1 {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 120%;
  font-weight: 600;
}
.text {
  align-self: stretch;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: 24px;
}
.makingItAccessible {
  margin-bottom: 0;
}
.makingItAccessibleForBrand {
  margin: 0;
  font-size: inherit;
  padding-left: 19px;
}
.text3 {
  flex: 1;
  position: relative;
  line-height: 140%;
}
.circle,
.text2 {
  border-radius: 9999px;
  display: flex;
}
.text2 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
}
.circle {
  flex: 1;
  background-color: rgba(13, 191, 196, 0.2);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 48px 52px;
  box-sizing: border-box;
  gap: 8px;
  min-width: 240px;
}
.textTitleHero3 {
  width: 46px;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 120%;
  display: inline-block;
}
.allInOne {
  margin: 0;
}
.circle1 {
  flex: 1;
  border-radius: 9999px;
  background-color: rgba(94, 105, 176, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 48px 52px;
  box-sizing: border-box;
  gap: 8px;
  min-width: 240px;
}
.textTitleHero5 {
  width: 48px;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 120%;
  display: inline-block;
}
.text9 {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 29px;
  font-weight: 600;
}
.circle2,
.tiles {
  display: flex;
  align-items: center;
}
.circle2 {
  flex: 1;
  border-radius: 9999px;
  background-color: rgba(107, 219, 178, 0.4);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px 48px 61px;
  box-sizing: border-box;
  gap: 8px;
  min-width: 240px;
}
.tiles {
  width: 992px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  max-width: 100%;
  text-align: left;
  font-size: 72px;
}
.starIcon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 16px;
}
.button1 {
  position: relative;
  font-size: 16px;
  line-height: 100%;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 106px;
}
.arrowRightIcon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 16px;
}
.button {
  cursor: pointer;
  border: 0;
  padding: 12px;
  background-color: #00b1a0;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration: none; /* Removes underline */
  color: inherit; /* Inherits the text color from the button styles */
}
.graphicIcon {
  width: 1072px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  object-fit: cover;
  max-width: 100%;
}
.section {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 64px 0;
  box-sizing: border-box;
  gap: 64px;
  max-width: 100%;
  text-align: center;
  font-size: 48px;
  color: #2b4a6b;
}
@media screen and (max-width: 1050px) {
  .section {
    gap: 32px;
    padding-left: 32px;
    padding-top: 42px;
    padding-right: 32px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .textTitlePage1 {
    font-size: 38px;
    line-height: 46px;
  }
  .textTitleHero1,
  .textTitleHero3,
  .textTitleHero5 {
    font-size: 58px;
    line-height: 69px;
  }
  .section {
    gap: 16px;
    padding-top: 27px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .textTitlePage1 {
    font-size: 29px;
    line-height: 35px;
  }
  .textTitleHero1 {
    font-size: 43px;
    line-height: 52px;
  }
  .text1 {
    font-size: 19px;
    line-height: 23px;
  }
  .circle {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .textTitleHero3 {
    font-size: 43px;
    line-height: 52px;
  }
  .circle1 {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .textTitleHero5 {
    font-size: 43px;
    line-height: 52px;
  }
  .text9 {
    font-size: 19px;
    line-height: 23px;
  }
  .circle2 {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
