.giftBox1Icon {
  height: 80px;
  width: 80px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.plusIcon {
  height: 30px;
  width: 30px;
  position: relative;
}
.tiles {
  width: 1072px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 64px;
  max-width: 100%;
}
.pairingAnExcellentCustomer {
  margin: 0;
}
.textTitlePageContainer {
  margin: 0;
  width: 875px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 120%;
  font-weight: 600;
  display: inline-block;
}
.textTitlePage {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.graphicIcon {
  width: 600px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  max-width: 100%;
}
.graphicWrapper,
.section {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}
.graphicWrapper {
  width: 876px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1px 0 0;
}
.section {
  align-self: stretch;
  background-color: #5e69b0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 107.5px 20px 43.5px;
  gap: 64px;
  text-align: center;
  font-size: 24px;
  color: #fff;
}
@media screen and (max-width: 1050px) {
  .section {
    gap: 32px;
  }
}
@media screen and (max-width: 750px) {
  .section {
    padding-top: 70px;
    padding-bottom: 28px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .tiles {
    flex-wrap: wrap;
  }
  .textTitlePageContainer {
    font-size: 19px;
    line-height: 23px;
  }
  .section {
    gap: 16px;
  }
}
