.textTitlePage1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 29px;
  font-weight: 600;
}
.textTitlePage {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
}
.textTitlePage3 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 58px;
  font-weight: 700;
  background: linear-gradient(90deg, #00b1a0, #41ad49);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header,
.textTitlePage2 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.textTitlePage2 {
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  font-size: 48px;
}
.header {
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
}
.memberBrandSaku1 {
  width: 957.7px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}
.convenience {
  margin: 0;
}
.multipleTailoredRewards {
  margin: 0;
  color: #2b4a6b;
}
.text,
.tile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.text {
  width: 244px;
  justify-content: flex-start;
}
.tile {
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px 28px;
  min-width: 338px;
  min-height: 192px;
  max-width: 100%;
}
.marketingAsA {
  margin: 0;
  color: #41ad49;
}
.text15 {
  position: relative;
  line-height: 140%;
}
.text14,
.tile1,
.tiles {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.text14 {
  justify-content: flex-start;
  color: #2b4a6b;
}
.tile1,
.tiles {
  justify-content: center;
  max-width: 100%;
}
.tile1 {
  flex: 1;
  flex-wrap: wrap;
  gap: 32px 28px;
  min-width: 338px;
  min-height: 232px;
  color: #41ad49;
}
.tiles {
  width: 1072px;
  gap: 32px;
  font-size: 14px;
  color: #0dbfc4;
}
.graphicIcon,
.memberSaku1 {
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}
.memberSaku1 {
  width: 407.3px;
  display: none;
}
.graphicIcon {
  width: 500px;
  overflow: hidden;
}
.textTitlePage7 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 58px;
  font-weight: 700;
}
.textTitlePage6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  font-size: 48px;
  color: #0dbfc4;
}
.oneStepRegistration {
  margin: 0;
  color: #0dbfc4;
}
.text20 {
  width: 244px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: #0dbfc4;
}
.subpoint {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 140%;
}
.text26 {
  height: 20px;
  width: 244px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.left,
.tile2 {
  display: flex;
  justify-content: center;
}
.tile2 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 32px 28px;
  min-height: 364px;
  font-size: 14px;
}
.left {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  min-width: 338px;
  max-width: 100%;
}
.brandSaku1,
.graphicIcon1 {
  position: relative;
  max-height: 100%;
  max-width: 100%;
}
.brandSaku1 {
  width: 406.8px;
  object-fit: cover;
  display: none;
}
.graphicIcon1 {
  width: 500px;
  overflow: hidden;
}
.textTitlePage10 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  font-size: 48px;
  color: #41ad49;
}
.keyPoint {
  flex: 1;
  position: relative;
  line-height: 140%;
}
.keyPoints {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 32px 28px;
  min-height: 344px;
  font-size: 14px;
  color: #41ad49;
}
.right {
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  min-width: 338px;
}
.right,
.section,
.tiles1 {
  display: flex;
  max-width: 100%;
}
.tiles1 {
  width: 1072px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
}
.section {
  align-self: stretch;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 64px 32px;
  box-sizing: border-box;
  gap: 64px;
  text-align: left;
  font-size: 24px;
  color: #2b4a6b;
}
@media screen and (max-width: 1275px) {
  .section {
    padding-top: 42px;
    padding-bottom: 42px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1100px) {
  .tile,
  .tile1 {
    min-height: auto;
  }
  .tiles {
    flex-wrap: wrap;
  }
  .section {
    gap: 32px;
    padding-top: 27px;
    padding-bottom: 27px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .textTitlePage3 {
    font-size: 38px;
    line-height: 46px;
  }
  .tiles {
    gap: 16px;
  }
  .textTitlePage7 {
    font-size: 38px;
    line-height: 46px;
  }
  .left,
  .right,
  .tiles1 {
    gap: 16px;
  }
  .section {
    gap: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .textTitlePage1 {
    font-size: 19px;
    line-height: 23px;
  }
  .textTitlePage3 {
    font-size: 29px;
    line-height: 35px;
  }
  .tile,
  .tile1 {
    min-width: 100%;
  }
  .textTitlePage7 {
    font-size: 29px;
    line-height: 35px;
  }
  .left,
  .right {
    min-width: 100%;
  }
}
