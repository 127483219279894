.textTitlePage1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 120%;
  font-weight: 700;
}
.textTitlePage {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.selfConfidenceValue {
  flex: 1;
  position: relative;
  line-height: 140%;
}
.circle,
.text {
  align-self: stretch;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.circle {
  flex: 1;
  background-color: #41ad49;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 48px 80px;
}
.sakuGuy11 {
  width: 324px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  display: none;
  max-width: 100%;
  z-index: 2;
}
.selfConfidencebroIcon {
  width: 317.1px;
  height: 300px;
  position: relative;
  z-index: 1;
  margin-top: -200px;
}
.circle1,
.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.tile {
  height: 460px;
  max-width: 100%;
}
.circle1 {
  align-self: stretch;
  flex: 1;
  border-radius: 9999px;
  background-color: #00b1a0;
  overflow: hidden;
  padding: 48px 80px;
}
.brandConstructionbroIcon,
.sakuGuy111 {
  position: relative;
  max-height: 100%;
  max-width: 100%;
}
.sakuGuy111 {
  width: 324px;
  object-fit: contain;
  display: none;
  z-index: 2;
}
.brandConstructionbroIcon {
  width: 345.2px;
  z-index: 1;
  margin-top: -200px;
}
.circle2 {
  align-self: stretch;
  flex: 1;
  border-radius: 9999px;
  background-color: #5e69b0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 48px 80px;
}
.onlinePopularitybroIcon {
  width: 251.5px;
  height: 300px;
  position: relative;
  z-index: 1;
  margin-top: -200px;
}
.section,
.tiles {
  justify-content: center;
  max-width: 100%;
}
.tiles {
  width: 1080px;
  display: grid;
  flex-direction: row;
  align-items: flex-start;
  grid-template-columns: repeat(3, minmax(270px, 1fr));
  font-size: 14px;
}
.section {
  align-self: stretch;
  background-color: #0dbfc4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 32px 0;
  box-sizing: border-box;
  gap: 32px;
  text-align: center;
  font-size: 48px;
  color: #fff;
}
@media screen and (max-width: 1050px) {
  .tiles {
    grid-row-gap: 20px;
    justify-content: center;
    grid-template-columns: minmax(270px, 350px);
  }
}
@media screen and (max-width: 750px) {
  .textTitlePage1 {
    font-size: 38px;
    line-height: 46px;
  }
  .tiles {
    grid-template-columns: minmax(270px, 350px);
  }
  .section {
    gap: 16px;
    padding-top: 42px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .textTitlePage1 {
    font-size: 29px;
    line-height: 35px;
  }
  .circle,
  .circle1,
  .circle2 {
    box-sizing: border-box;
  }
}
